.container-product{
    display: grid;
    width: 100%;
    justify-content: center;
    width: auto;
    margin-bottom: 8px;
    position: relative;
    margin-right: 7px;

    .card {
        //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border: none;
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;
        background: #fff;
        overflow: hidden;

        .card-header{
            display: flex;
            background-color: transparent;
            border: none;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;

            .arrow-wrapper {
                width: 45px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(244, 244, 244, 0.7);
                border-radius: 50%;
                cursor: pointer;
                overflow: hidden;
                position: relative;

                @media screen and (max-width: 760px) {
                  width: 35px;
                  height: 35px;
                }
              }
    
              .arrow-image-left {
                height: 83px;
                width: 127px;
                object-fit: contain;
                margin-left: -4px;

                @media screen and (max-width: 760px) {
                  height: 63px;
                  width: 107px;
                }
              }
              .arrow-image-right {
                height: 83px;
                width: 127px;
                object-fit: contain;
                margin-right: -4px;

                @media screen and (max-width: 760px) {
                  height: 63px;
                  width: 107px;
                }
              }
            }
        }
    .product-image {
        width: 100%;
        position: relative;
        max-height: 429px;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .card-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1em;
            box-sizing: border-box;
            background-color: transparent;
            border: none;
            font-size: 18px;
        
          .icon-bookmark,
            .icon-gem {
                font-size: 1.5em;
                transition: color 0.3s ease-in-out;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5em;
                height: 1.5em;
                
                @media screen and (max-width: 760px) {
                  font-size: 1.1em;
                }
          }

          .gem-background {
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background-color: black;
            border-radius: 50%;
            z-index: 0;
          }
          
          .icon-gem.selected .gem-icon {
              color: white;
              z-index: 1;
          }
        }
    }

    .card-details-footer {
        background-color: #ffffff;
        text-align: center;
        flex-direction: column;
        padding: 15px 0;

        .card-material {
          font-size: 12px;
          font-family: 'Mont-Regular', sans-serif;
          margin: 0;

          @media screen and (max-width: 760px) {
            font-size: 10px;
          }
        }

        .card-title {
          font-size: 20px;
          font-family: 'Mont-Regular', sans-serif;
          margin: 0;

          @media screen and (max-width: 760px) {
            font-size: 18px;
          }
        }

        .card-price {
          font-size: 18px;
          font-family: 'Mont-Regular', sans-serif;
          margin: 0;

          @media screen and (max-width: 760px) {
            font-size: 15px;
          }
        }
        

        .card-size-container {
          display: flex;
          gap: 8px;
          justify-content: center;
          font-family: 'Mont-Regular', sans-serif;
        }

        .size-box {
          background-color: #F4F4F4;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 5px;
          font-size: 9px;
          text-align: center;
          width: auto;
          height: 20px;
          font-size: 10px;
        }

        .color-circle {
          margin-top: 5px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;

          @media screen and (max-width: 760px) {
            width: 15px;
            height: 15px;
          }
        }

        .color-circle-split {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: inline-block;

          @media screen and (max-width: 760px) {
            width: 15px;
            height: 15px;
          }
        } 
    }
}


.price {
    color: grey;
    font-size: 22px;
    margin-bottom: 10px;
}
