.approved-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    transition: all 0.3s ease-in-out;

    .left-panel {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ddd;
        padding: 20px;
        height: auto;
        background-color: #f9f9f9;

        .back-button {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            border: none;
            padding: 8px 15px;
            font-size: 14px;
            cursor: pointer;
            border-radius: 5px;
            z-index: 10;
        }

        .back-button:hover {
            background-color: rgba(0, 0, 0, 0.9);
        }

        .image-wrapper {
            position: relative;
            display: inline-block;

            .selected-image {
                max-width: 100%;
                max-height: 100%;
            }

            .marker {
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: rgb(0, 0, 0);
                color: white;
                font-size: 10px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        flex: 2;

        .image-box {
            border: 2px solid #ccc;
            cursor: pointer;
            transition: transform 0.2s ease-in-out;
        }

        .image-box:hover {
            transform: scale(1.05);
        }

        .image-box img {
            width: 100%;
            height: auto;
        }
    }

    .right-panel {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 10px;

        .search-bar {
            padding: 10px;
            font-size: 16px;
            border: 2px solid #ccc;
            border-radius: 5px;
            width: 100%;
        }
    }
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;

    .image-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .image-box img {
        width: 100%;
        height: auto;
    }

    .approve-button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 6px 10px;
        background-color: transparent;
        color: rgb(255, 255, 255);
        border: none;
        cursor: pointer;
        //border-radius: 5px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .image-box:hover .approve-button {
        opacity: 1;
    }

    .approve-button:hover {
        background-color: #218838;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;

    .page-button {
        padding: 8px 12px;
        border: 2px solid #007bff;
        background-color: white;
        color: #007bff;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        transition: 0.3s ease;
    }

    .page-button:hover {
        background-color: #007bff;
        color: white;
    }

    .page-button.active {
        background-color: #007bff;
        color: white;
        font-weight: bold;
    }
}

.instagram-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;

    .instagram-item {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;

        .instagram-item img,
        .instagram-item video {
            max-width: 60%;
            height: auto;
            border-radius: 8px;
        }
    }
}